import * as moment from 'moment';

export const configuration = {
    full_circle_steps: 24,
    step_values_in_minutes: [5, 10, 20, 60, 2 * 60, 4 * 60],
    default_value: 0.125,
    default_park_until_time: moment().add(15, 'm'),
    default_step: 3,
    default_total_minutes: 15,
    maximum_total_minutes: 60 * 24 * 21,
};
