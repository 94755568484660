import * as React from 'react';
import {Button, Spacer} from '@autopay.io/style';
import {ExpandableTable} from './ExpandableTable/ExpandableTable';
import {Translation} from '@autopay.io/translation';

const AutopayInformation = () => {
    return (
        <div className={'autopay-information'}>
            <ExpandableTable title={Translation.messages().priceCalculator.payment_options.title}>
                <div className={'payment-option'}>
                    <p className={'title'}>1. {Translation.messages().priceCalculator.payment_options.pay_automatically}</p>
                    <p>{Translation.messages().priceCalculator.payment_options.pay_automatically_description}</p>
                </div>
                <div className={'payment-option'}>
                    <p className={'title'}>2. {Translation.messages().priceCalculator.payment_options.payment_machines}</p>
                    <p>{Translation.messages().priceCalculator.payment_options.payment_machines_description}</p>
                </div>
                <div className={'payment-option'}>
                    <p className={'title'}>3. {Translation.messages().priceCalculator.payment_options.pay_online}</p>
                    <p>{Translation.messages().priceCalculator.payment_options.pay_online_description}</p>
                </div>
                <div className={'payment-option'}>
                    <p className={'title'}>4. {Translation.messages().priceCalculator.payment_options.pay_by_invoice}</p>
                    <p>{Translation.messages().priceCalculator.payment_options.pay_by_invoice_description}</p>
                </div>
            </ExpandableTable>
            <ExpandableTable title={Translation.messages().priceCalculator.sign_up.title}>
                <div className={'payment-option'}>
                    <p className={'title'}>{Translation.messages().priceCalculator.sign_up.sign_up}</p>
                    <p>{Translation.messages().priceCalculator.sign_up.sign_up_description}</p>
                    <Spacer size={'xs'} />
                    <a href={'https://autopay.io/signup'}>
                        <Button color={'primary'} className={'sign-up-button'}>{Translation.messages().priceCalculator.sign_up.sign_up_button}</Button>
                    </a>
                </div>
            </ExpandableTable>
        </div>
    );
};

export default AutopayInformation;
