import {getEnvironment} from '@autopay.io/common/lib/util';

export const getAutopayIoUrl = () => {
    const env = getEnvironment();
    let urlPrefix;
    if (env === 'local') {
        urlPrefix = 'test.';
    } else if (env === 'prod') {
        urlPrefix = '';
    } else {
        urlPrefix = `${env}.`;
    }
    return `https://${urlPrefix}autopay.io`;
};
