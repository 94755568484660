import * as React from 'react';
import {useEffect, useState} from 'react';
import {Message, Spinner} from '@autopay.io/style';
import {getFacilityWithZones} from '../../services/http';
import {PriceCalculatorViewState} from './types';
import PriceCalculator from './PriceCalculator';
import './style.less';
import {Translation} from '@autopay.io/translation';
import {useParams} from 'react-router-dom';

export const PriceCalculatorIndex = () => {
    const {facility} = useParams() as {facility: string};

    const [viewState, setViewState] = useState<PriceCalculatorViewState>({type: 'LOADING'});

    useEffect(() => {
        getFacilityWithZones(facility).then((response) => {
           if (response.type === 'SUCCESS') {
               setViewState({type: 'DATA', facility: response.data});
            } else if (response.type === 'ERROR_MESSAGE') {
               setViewState({type: 'ERROR', errorId: response.errorId});
           } else {
               setViewState({type: 'ERROR'});
           }
        });
    }, [facility]);

    switch (viewState.type) {
        case 'ERROR':
            if (viewState.errorId && viewState.errorId === 'facility_not_found_error') {
                return <Message type="error" message={Translation.messages().priceCalculator.error.facility_not_found} />;
            } else {
                return <Message type="error" message={Translation.messages().priceCalculator.error.something_went_wrong} />;
            }
        case 'LOADING':
            return <Spinner size="md" />;
        case 'DATA':
            return <PriceCalculator facilityCode={facility} facility={viewState.facility} />;
    }
};
