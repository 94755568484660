import * as React from 'react';
import {useEffect, useState} from 'react';
import {FormGroup, Label, Message, Select, Spacer} from '@autopay.io/style';
import {Facility, Zone} from './types';
import AutopayInformation from './components/AutopayInformation';
import {PriceModule} from './components/PriceModule/PriceModule';
import {Translation} from '@autopay.io/translation';
import {useLocation} from 'react-router-dom';

interface PriceCalculatorProps {
    facilityCode: string;
    facility: Facility;
}

const PriceCalculator = ({facilityCode, facility}: PriceCalculatorProps) => {
    const [selectedZone, setSelectedZone] = useState<Zone>(facility.zones[0]);
    const [priceCalculationError, setPriceCalculationError] = useState<string | null>(null);
    const location = useLocation();
    const params = new URLSearchParams(location.search);

    useEffect(() => {
        if (params.has('zone')) {
            const zone = facility.zones.find((item) => item.zoneId === params.get('zone'));
            if (zone !== undefined) {
                setSelectedZone(zone);
            }
        }
    }, []);

    function handleZoneChange(event: React.FormEvent<HTMLSelectElement>) {
        const zone = facility.zones.find((z) => z.zoneName === event.currentTarget.value);
        if (zone) {
            setSelectedZone(zone);
        }
    }

    function renderErrorMessage() {
        if (priceCalculationError === 'parking_max_length_exceeded_error') {
            return <Message type="error" message={Translation.messages().priceCalculator.error.max_parking_length_exceeded} />;
        } else if (priceCalculationError === 'too_many_requests') {
            return <Message type="error" message={Translation.messages().priceCalculator.error.too_many_requests} />;
        } else {
            return <Message type="error" message={Translation.messages().priceCalculator.error.error_calculating_price} />;
        }
    }

    return (
        <>
            <h1>{Translation.messages().priceCalculator.title}</h1>
            <Spacer size={'sm'} />
            <h2>{facility.facilityName}</h2>
            <Spacer size={'xxs'} />
            <p>{Translation.messages().priceCalculator.description}</p>
            <Spacer size={'sm'} />
            <div className={'content'}>
                {facility.zones.length > 1 && (
                    <FormGroup className={'zone-form'}>
                        <Label>{Translation.messages().priceCalculator.select_zone}</Label>
                        <Select
                            id="tenant"
                            options={facility.zones.map((zone) => ({label: zone.zoneName, key: zone.zoneId, value: zone.zoneName}))}
                            onChange={handleZoneChange}
                            defaultValue={facility.zones.find((zone) => zone.zoneId === params.get('zone'))?.zoneName ?? undefined}
                        />
                    </FormGroup>
                )}
                {priceCalculationError && (
                    <>
                        <Spacer size={'xs'} />
                        {renderErrorMessage()}
                    </>
                )}
                <Spacer size={'md'} />
                <PriceModule
                    operator={facility.operator}
                    facilityCode={facilityCode}
                    zone={selectedZone}
                    setPriceCalculationError={setPriceCalculationError}
                />
                <Spacer size={'md'} />
                <AutopayInformation />
            </div>
        </>
    );
};

export default PriceCalculator;
