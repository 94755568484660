import * as React from 'react';
import {Icon} from '@autopay.io/style';
import './expandableTable.less';

export interface ExpandableTableProps {
    title: string;
    children: JSX.Element[] | JSX.Element;
    openByDefault?: boolean;
}

export const ExpandableTable = (props: ExpandableTableProps) => {
    const initialOpenState = typeof props.openByDefault === 'undefined' ? false : props.openByDefault;

    const [open, setOpen] = React.useState<boolean>(initialOpenState);

    const toggleOpen = () => setOpen(!open);

    function renderBody() {
        if (!open) {
            return null;
        }
        return (
            <div className="expandable-table-body">
                {props.children}
            </div>
        );
    }

    return (
        <div className={'expandable-table'}>
            <div className="header" onClick={toggleOpen}>
                <div className="header-inner">
                    <h2>{props.title}</h2>
                </div>
                <button onClick={toggleOpen} className="toggle-icon">
                    <Icon icon={open ? 'arrow_up' : 'arrow_down'} />
                </button>
            </div>
            {renderBody()}
        </div>
    );
};
