import './index.less';
import '@autopay.io/style/autopay-io.css';

import 'whatwg-fetch';
import 'core-js/es6/set';
import * as React from 'react';
import {render} from 'react-dom';
import {BrowserRouter, matchPath} from 'react-router-dom';
import Router from './router';
import {Translation} from '@autopay.io/translation';
import {getAllStringsFromObject} from '@autopay.io/common/lib/getAllStringsFromObject';
import {getDomain} from '@autopay.io/common/lib/util';
import Plausible from 'plausible-tracker';
import {createBrowserHistory, Location} from 'history';
import {routes} from './router';

// https://plausible.io/docs/integration-guides#npm-package
const plausible = Plausible({domain: `rates.${getDomain()}`});
export const browserHistory = createBrowserHistory();

export const renderApp = () => {
    render(<App />, document.getElementById('app'));
};

export const App = () => {
    Translation.addLanguageChangeListener(() => renderApp());

    React.useEffect(() => {
        logPathToPlausible(browserHistory.location);
        browserHistory.listen((location) => logPathToPlausible(location));
    }, []);

    return (
        <BrowserRouter>
            <Router />
        </BrowserRouter>
    );

};

function logPathToPlausible(location: Location) {
    let path = location.pathname;
    const allPaths = getAllStringsFromObject(routes);
    allPaths.map((route) => {
        if (matchPath(path, {path: route, exact: true}) !== null) {
            path = route;
        }
    });
    plausible.trackPageview({url: path});
}

renderApp();
