import * as React from 'react';
import {Route, Switch} from 'react-router-dom';
import {AioPage, WizardNavbar} from '@autopay.io/style';
import {PriceCalculatorIndex} from './scenes/priceCalculator';
import {Translation} from '@autopay.io/translation';
import {getAutopayIoUrl} from './utils';

export const routes = {
    ROOT: '/:facility',
};

const Router = () => {

    return (
        <AioPage type="page-with-header">
            <WizardNavbar landingUrl={getAutopayIoUrl()} translation={Translation} />
            <Switch>
                <Route path={routes.ROOT}>
                    <PriceCalculatorIndex />
                </Route>
            </Switch>
        </AioPage>
    );
};

export default Router;
