import {ApiMethods, HttpGet, HttpResponse} from '../types/http';
import {Facility, Price} from '../scenes/priceCalculator/types';
import {apiMap} from '@autopay.io/common/lib/util';

export function getFacilityWithZones(facilityCode: string): Promise<HttpResponse<Facility>> {
    const url = `${apiMap.priceCalculator}/facility-zones/${facilityCode}`;
    return httpGet<Facility>({url});
}

export function getEstimatedPrice(operator: string, facilityCode: string, zoneId: string, minutes: number): Promise<HttpResponse<Price>> {
    const url = `${apiMap.priceCalculator}/price?operator=${operator}&facilityCode=${facilityCode}&zoneId=${zoneId}&minutes=${minutes}`;
    return httpGet<Price>({url});
}

export function httpGet<T>({url}: HttpGet): Promise<HttpResponse<T>> {
    return http(url, 'GET');
}

function http<T>(url: string, method: ApiMethods): Promise<HttpResponse<T>> {
    return fetch(
        url, {method},
    ).then(async (response: Response): Promise<HttpResponse<T>> => {
        const contentType = response.headers.get('content-type');

        if (contentType !== null && contentType.indexOf('application/json') !== -1 && response.status >= 200 && response.status <= 300) {
            const parsedJson = await response.json();

            return {data: parsedJson, type: 'SUCCESS'};
        } else if (response.ok) {
            return { type: 'OK' };
        } else if (contentType !== null && contentType.indexOf('application/json') !== -1 && response.status === 400) {
            const json = await response.json();
            return {type: 'ERROR_MESSAGE', errorId: json.errorId};
        } else if (response.status === 429) {
            return {type: 'ERROR_MESSAGE', errorId: 'too_many_requests'};
        } else {
            return Promise.reject();
        }
    });
}
